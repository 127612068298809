@use 'sass:map';
@use 'variables';
@use 'utilities/separators';

/* Size */
.w-fit-content {
  width: fit-content;
}

/* Background color */
@each $color, $value in variables.$colors {
  .jn-bg-#{$color} {
    background-color: $value;
  }
}

/********************************************************************
 * Font
 *******************************************************************/
$font-prefix: 'jn-font-';

/* Font color */
@each $color, $value in variables.$colors {
  .jn-font-#{$color} {
    color: $value !important;

    &:hover,
    &:visited {
      color: $value;
    }
  }
}

.jn-font-github-logo-color {
  color: variables.$github-logo-color !important;
}

.jn-font-linkedin-blue {
  color: variables.$linkedin-blue !important;
}

/* Font size */
$custom-font-sizes: (
  '6': 10rem, // was xxl
  '5': 5rem, // was xl
  '4': 4rem,
  '3': 3.5rem, // was axl
  '2': 2.5rem, // was l
  '1': 1.85rem // was m
);

@each $breakpoint, $value in variables.$breakpoints {
  @each $size, $size-value in $custom-font-sizes {
    @if ($breakpoint != 'xs') {
      @media only screen and (min-width: $value) {
        .#{$font-prefix}#{$breakpoint}-#{$size} {
          font-size: map.get($custom-font-sizes, $size);
        }
      }
    } @else {
      .#{$font-prefix}#{$size} {
        font-size: map.get($custom-font-sizes, $size);
      }
    }
  }
}

/* dividers */

.divider-right {
  position: relative;
  padding-right: 20px;

  &::after {
    position: absolute;
    content: '';
    height: 70%;
    top: 15%;
    right: 0;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 1px;
  }
}

@each $breakpoint, $value in variables.$breakpoints {
  @if ($breakpoint != 'xs') {
    @media only screen and (min-width: $value) {
      .divider-#{$breakpoint}-right {
        padding-right: 20px;

        &::after {
          height: 70%;
          top: 15%;
        }
      }
    }
  } @else {
    .divider-right {
      padding-right: 20px;

      &::after {
        height: 70%;
        top: 15%;
      }
    }
  }
}

/* Gutter Containers */

.gutter-container {
  display: flex;
  flex-wrap: wrap;
}

.flex-gap-xsm {
  --gap-width: 5px;
  gap: variables.$gap-width;
}

.flex-gap-md {
  --gap-width: 20px;
  gap: variables.$gap-width;
}