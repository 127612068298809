@use 'sass:map';
@use '../variables';

$separator-prefix: ".jn-separator-";
$separator-heights: (
    "300": 300px,
    "full": 100%,
);
$separator-locations: (
    "start::before": 'height',
    "end::after": 'height',
    "top::before": 'width',
    "bottom::after": 'width',
);

.jn-separator {
    content: '';
    border: 1px solid map.get(variables.$colors, "super-light-blue");
    border-radius: 1px;
    position: static;

    @each $breakpoint, $value in variables.$breakpoints {

        @if ($breakpoint != 'xs') {
            @media only screen and (min-width: $value) {
                @each $height, $height-value in $separator-heights {
                    @each $location, $location-value in $separator-locations {
                        &-#{$height}-#{$location} {
                            #{$location-value}: $height-value;
                        }
                    }
                }
            }
        } @else {
            @each $height, $height-value in $separator-heights {
                @each $location, $location-value in $separator-locations {
                    &-#{$height}-#{$location} {
                        @extend .jn-separator;
                        #{$location-value}: $height-value;
                    }
                }
            }
        }
    }
}

@each $breakpoint, $value in variables.$breakpoints {
    @if ($breakpoint != 'xs') {
        @media only screen and (min-width: $value) {
            .jn-separator-#{$breakpoint}-none {
                &::after,
                &::before {
                    border: none;
                }
            }
        }
    } @else {
        .jn-separator-none {
            &::after,
            &::before {
                border: none;
            }
        }
    }
}