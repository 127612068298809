@use 'sass:map';
@use 'variables';

body, #root {
    font-family: Arial, Helvetica, sans-serif;
    max-width: 100vw;
    min-height: 100vh;
    background-color: map.get(variables.$colors, "bg-gray");
    color: map.get(variables.$text-colors, "base-text-color");
}

#root:not(h1, h2, h3, h4, h5) {
    font-size: 1.25rem;
}

h2:not(:first-of-type),
h3:not(:first-of-type),
h4:not(:first-of-type),
h5:not(:first-of-type) {
    margin-top: 2rem;
}

main {
    min-height: calc(100vh - variables.$header-height);
    width: 100%;
}

/* Links */
a {
    &:link,
    &:visited {
        text-decoration: none;
    }

    &:not(.btn) {
        &:visited {
            color: map.get(variables.$colors, "pop-blue");
        }
    }
}